.indicator {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 1px;

  &.danger {
    background: var(--dangerColor);
  }

  &.normal {
    background: var(--vipBackground);
  }

  &.good {
    background: var(--darkActiveColor);
  }
}

.menuItem {
  padding: 16px;
  color: black;
  height: 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
}

.menuItem:hover {
  cursor: pointer;
  background: rgb(0 0 0 / 10%);
}

.icon {
  display: flex;
  align-items: center;
  margin-right: 8px;
}

.dangerousItem {
  color: #ea4551;
}

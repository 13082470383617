.fineAmount {
  margin-bottom: 8px;
}

.trapModal {
  position: absolute;
  top: calc(100% + 8px);
  background: #ea4551;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  z-index: 3;
  padding: 16px 32px 16px 16px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  width: 300px;
}

.dockToRight {
  right: 0;
}

.dockToLeft {
  left: 0;
}

.closeButton {
  position: absolute;
  top: 8px;
  right: 8px;
}

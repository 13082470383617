.body {
  background: #242526;
  border-radius: 16px;
  padding: 32px;
  width: 100%;
  margin-bottom: 24px;
  height: 109px;
  min-height: 109px;

  display: flex;
  justify-content: space-between;
}

.explanation {
  font-size: 20px;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.explanationTitle {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.badValue {
  color: #ea4551;
}

.goodValue {
  color: #009f83;
}

.explanationValue {
  margin-left: 5px;
}

.explanationDetails {
  font-size: 12px;
  color: #616466;
  font-weight: 400;
}

.money {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  display: flex;
  align-items: center;
}

.webcamContent {
  width: 850px;
  height: 650px;
  position: relative;
}

.webcamContentHidden {
  display: none;
}

.videoContainer {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.photoContainer {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.takePhotosContainer {
  position: absolute;
  width: 100%;
  bottom: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  button + button {
    margin-left: 20px;
  }
}

.takePhotoButton {
  min-width: 160px;
}

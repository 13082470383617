.dropzoneContainer {
  position: absolute;
  left: 0px;
  right: 15px; /* to stop the dropzone from covering the scrollbar */
  top: 0px;
  bottom: 0px;
  display: flex;
  z-index: 0;
}

.dropzoneActiveContainer {
  z-index: 11;
  background-color: #242526;
}

.dropzoneContentWrapper {
  position: fixed;
  display: flex;
  padding: 24px;
  background-color: #242526;
}

.dropzoneContent {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px dashed #45494d;

  img {
    margin-bottom: 10px;
    width: 40px;
  }

  p {
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    color: #616466;
  }
}

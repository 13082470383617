$size: 24px;

.container {
  display: flex;
  position: relative;
  align-items: center;
  margin-left: 16px;
}

.button {
  width: $size;
  height: $size;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 101;
  cursor: pointer;
}

.modal {
  position: absolute;
  bottom: $size + 30px;
  right: $size * 0.5;
  transform: translateX(50%);
  z-index: 100;
}

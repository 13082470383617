.afterBotMessageWrapper {
  width: 100%;
  padding-left: 145px;
  font-size: 10px;
  text-align: right;
  color: #616466;
  align-items: flex-end;
  display: flex;
  flex-direction: column;
}

.afterBotMessage {
  display: inline;
  margin: 4px 0;
}

.centerHeader {
  display: flex;
  width: 60%;
  flex-grow: 3;
  justify-content: space-between;
  align-items: center;
  max-width: 900px;
  padding: 0 12px;
  height: 100%;
}

.messageSearchMode {
  background-color: var(--new-design--dark-background);
}

.scales,
.controls {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
}

.userContainer {
  text-align: center;
  text-decoration: none;

  :global(.dark) & {
    background-color: var(--secondaryBackgroundColor);
  }

  a {
    color: inherit;
    text-decoration: none;
  }
}

.thumbnailContainer {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;
}

.thumbnail {
  display: block;
  width: 56px;
  height: 56px;
  border-radius: 50%;
}

.thumbnailMock {
  background-color: var(--darkBorderColor);
}

.location {
  display: block;
  font-size: 12px;
  color: var(--placeholderColor);
}

.occupation {
  composes: location;
}

.container {
  margin-top: 16px;
  margin-bottom: 8px;
}

.label {
  font-size: 12px;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 8px;

  span {
    user-select: none;
  }

  input {
    margin-right: 10px;
  }
}

.checked {
  accent-color: #ea4551;
}

.labelChecked {
  color: #ea4551;
}

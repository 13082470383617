.button {
  background: var(--new-design--default-dark);
  border-radius: 4px;
  color: var(--darkTextColor);
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: var(--btn-hover-color);
  }

  &:active {
    background-color: var(--btn-active-color);
  }
}

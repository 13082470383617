/* global variables */
:root {
  --vipBackground: #ffd100;
  --outOfBoltsBackground: #ffd100;

  /* white theme */
  --whiteForeground: #000;
  --whiteBackground: #fefefe;
  --whiteBodyBackground: #fefefe;
  --whiteTextColor: #000;
  --whiteActiveColor: #009f83;
  --whiteBorderColor: #eaeaea;

  /* dark theme */
  --darkForeground: #e6e6e6;
  --darkBodyBackground: #000;
  --darkTextColor: #fff;
  --darkActiveColor: #009f83;
  --darkBorderColor: #595959;
  --secondaryBackgroundColor: #242526;
  --inputBorderColor: #45494d;
  --placeholderColor: #616466;
  --dangerColor: #ea4551;

  --btn-hover-color: #ffffff33;
  --btn-active-color: #0000001a;

  --new-design--default-dark: #2f3133;
  --new-design--default-border-radius: 8px;
  --new-design--dark-background: #191919;
  --new-design--message-border-radius: 18px;

  --chatImageSize: 100px;
  --modal-inner-padding: 40px;
  --header-widget-height: 28px;
}

html {
  /* to make scrollbars always white, even when darkmode in ios is turned on*/
  color-scheme: dark;
}

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

$fonts: 'Nunito Sans', -apple-system, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
  'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;

/* nunito-sans-regular - latin-ext_latin */
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Nunito Sans Regular'), local('NunitoSans-Regular'),
    url('./fonts/nunito-sans-v5-latin-ext_latin-regular.woff2') format('woff2');
}

/* nunito-sans-700 - latin-ext_latin */
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Nunito Sans SemiBold'), local('NunitoSans-SemiBold'),
    url('./fonts/nunito-sans-v5-latin-ext_latin-700.woff2') format('woff2');
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: $fonts;
  font-size: 16px;
  color: var(--whiteTextColor);

  &.dark {
    background-color: var(--darkBodyBackground);
    color: var(--darkTextColor);
  }
}

button,
input,
textarea {
  font-family: $fonts;
}

button {
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
}

a {
  text-decoration: none;
  color: inherit;
}

.hidden {
  display: none !important;
}

.h-100 {
  min-height: 100vh;
}

.react-tooltip {
  padding: 16px !important;
  border-radius: var(--new-design--default-border-radius) !important;
  font-size: 12px !important;
  z-index: 1;
}

.react-tooltip.react-tooltip__show {
  opacity: 1 !important;
}

.playButton {
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.primary {
  background-color: var(--whiteActiveColor);
}

.secondary {
  background-color: var(--new-design--default-dark);
}

.progressBarWrapper {
  width: 12px;
  height: 12px;
}

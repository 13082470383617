$titleFontSize: 20px;
$contentOffset: 32px;

.container {
  height: 100%;
}

.placeholderContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.title {
  text-align: center;
  font-size: $titleFontSize;
}

.content {
  display: flex;
  flex-direction: row;
  margin-top: $contentOffset;
  height: calc(100% - #{$titleFontSize} - #{$contentOffset});
  gap: 20px;
}

.container {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-left: 4px;
}

.itemWrapper {
  padding: 0 16px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: calc(100% / 5);
  overflow: hidden;

  & + & {
    margin-left: 4px;
  }

  &:focus {
    outline: none;
  }
}

.itemWrapperStretch {
  width: auto;
  flex: 1 1 100%;
}

.itemTitle {
  font-size: 12px;
  color: var(--darkTextColor);
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: anywhere;
}

.itemCaption {
  font-size: 8px;
  text-transform: uppercase;
  color: var(--placeholderColor);
  margin-bottom: 2px;
}

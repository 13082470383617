.container {
  color: #fff;
  font-size: 12px;
  padding: 16px;
}

.bubble {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  color: var(--new-design--dark-background);
  font-size: 8px;
  font-weight: 600;
  vertical-align: middle;
  text-align: center;
  line-height: 20px;
}

.high {
  background-color: #1ed760;
}

.medium {
  background-color: #ffc702;
}

.low {
  background-color: #ff550c;
}

.content {
  margin-top: 16px;
}

.contentTitle {
  color: #009f83;
  font-size: 12px;
  margin-bottom: 8px;
}

.contentValue {
  font-size: 12px;
}

.loaderWrapper {
  text-align: center;
  padding: 32px;

  & > svg {
    width: 20px;
    height: 20px;
  }
}

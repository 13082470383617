.container {
  height: 48px;
  border-radius: 25px;
  background-color: #2e2e2e;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: #fff;
  border: 0;
  outline: none;
  padding-left: 24px;
  padding-right: 24px;
  cursor: pointer;
  transition: 0.3s ease;

  &:hover {
    opacity: 0.7;
  }

  &.disabled,
  &[disabled] {
    background-color: #2e2e2e;
    color: #808080;
    cursor: default;

    &:hover {
      opacity: 1;
    }
  }
}

.xs {
  height: 32px;
  font-size: 12px;
}

.sm {
  height: 36px;
  font-size: 14px;
}

.md {
  padding-left: 35px;
  padding-right: 35px;
}

.lg {
  padding-left: 48px;
  padding-right: 48px;
}

.green {
  background-color: var(--whiteActiveColor);

  :global(.dark) & {
    background-color: var(--darkActiveColor);
  }
}

.raspberry {
  background-color: var(--dangerColor);
}

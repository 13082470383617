.arrow {
  border-radius: 100px;
  background-color: #fafafa;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:active,
  &:focus {
    background-color: #e6e6e6;
  }

  &:hover {
    cursor: pointer;
  }
}

.iconPlaceholder {
  width: 36px;
  height: 36px;
}

.navigation {
  display: flex;
  flex-direction: row;
  font-size: 20px;
  font-weight: 700;
  width: 280px;
  align-items: center;
  justify-content: space-between;
  user-select: none;
}

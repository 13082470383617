.container {
  position: relative;
}

.buttonsLine {
  position: absolute;
  z-index: 10;
  top: 8px;
  right: 8px;
  display: flex;
}

.panel {
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--new-design--default-dark);
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 12;
  background: var(--new-design--dark-background);
  height: 100%;
}

.messageInput {
  margin: 0;
  border: 0;
  width: 100%;
  height: 100%;
  padding: 0 16px;
  font-size: 14px;
  background: transparent;
  color: var(--darkTextColor);

  &::-webkit-input-placeholder {
    color: var(--placeholderColor);
  }

  &::-moz-placeholder {
    color: var(--placeholderColor);
  }

  &:-ms-input-placeholder {
    color: var(--placeholderColor);
  }

  &:-moz-placeholder {
    color: var(--placeholderColor);
  }

  &:focus {
    outline: none;
  }
}

.progress {
  font-size: 14px;
  color: #fff;
  padding: 0 16px;
}

.activityButtons {
  display: flex;
  padding: 8px 18px;
  > * + * {
    margin-left: 8px;
  }
}

.prevButton,
.nextButton {
  background-color: var(--new-design--dark-background);

  svg {
    fill: #fff;
  }
}

.closeButton {
  svg {
    fill: #fff;
  }
}

.disabledButton {
  pointer-events: none;

  svg {
    fill: #595959;
  }
}

.row {
  margin-top: 24px;
  text-align: center;

  button + button {
    margin-left: 20px;
  }
}

.input {
  margin-top: 12px;
}

.required {
  color: var(--dangerColor);
  display: inline-block;
  text-align: start;
  width: 100%;
  margin-top: 5px;
}

.container {
  margin-top: 32px;
}

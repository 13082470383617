.optionSkeleton {
  width: 80px;
  height: 35px;

  &:hover,
  &:active,
  &:focus {
    cursor: default;
    background-color: unset;
  }
}

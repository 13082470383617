.messagesList {
  overflow-y: scroll;
  max-height: 100%;
  // this is needed to make an offset for scrollbar
  margin-right: -20px;
  padding-right: 20px;
}

.inner {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  padding-bottom: var(--modal-inner-padding);
}

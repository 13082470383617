.container {
  input + input {
    margin-top: 16px;
  }
}

.row {
  display: flex;
  gap: 16px;
  justify-content: center;
  margin-top: 32px;
  text-align: center;
}

.caption {
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 32px;
}

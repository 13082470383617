.container {
  width: 100%;
  font-size: 12px;
  padding: 17px;
}

.title {
  font-size: 12px;
  color: #616466;
  margin-bottom: 12px;
}

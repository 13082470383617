.container {
  background: var(--new-design--default-dark);
  border-radius: 16px;
  padding-left: 14px;
  padding-right: 16px;
  height: var(--header-widget-height);
  display: flex;
  cursor: pointer;

  * {
    cursor: pointer;
  }

  &:hover {
    background: var(--btn-hover-color);
  }

  &:active,
  &:focus {
    background: var(--btn-active-color);
  }
}

.input {
  opacity: 0.2;

  &:checked {
    accent-color: var(--whiteActiveColor);
    opacity: 1;
  }
}

.label {
  display: flex;
  align-items: center;
  gap: 8px;
  text-transform: uppercase;
  font-size: 8px;
  font-weight: 700;
}

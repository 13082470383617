.authPage {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: var(--darkBodyBackground);
}

.title {
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 32px;
}

.container {
  width: 100%;
  height: 128px;
  border-radius: var(--new-design--default-border-radius);
  border: solid 1px #e6e6e6;
  padding: 10px;
  font-size: 16px;
  transition: 0.3s ease;
  background: none;
  color: #000;
  resize: none;
  display: block;

  &:focus {
    outline: none;
  }
}

.required {
  border-color: var(--dangerColor);
}

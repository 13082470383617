.react-calendar {
  width: 280px;

  & button {
    color: black;
    font-size: 14px;
    font-weight: 400;
    background-color: white;
  }

  & abbr {
    text-decoration: none;
  }
}

.react-calendar__month-view__weekdays__weekday {
  text-align: center;
  color: #616466;
  text-transform: uppercase;
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  padding: 8px 0;
}

.react-calendar__month-view__days__day--neighboringMonth {
  visibility: hidden;
}

.react-calendar__navigation__label__labelText {
  font-size: 20px;
  font-weight: 700;
}

.react-calendar__navigation {
  text-align: center;
  margin-bottom: 16px;
  display: flex;
}

.react-calendar__tile {
  height: 40px;
  border: 1px solid #e6e6e6;
  position: relative;

  &:disabled {
    color: #e6e6e6;
    background-color: #fafafa;
  }

  &:focus,
  &:active {
    background-color: rgb(230 230 230 / 100%);
    color: rgb(0 0 0 / 80%);
  }

  &:hover:not(:disabled) {
    cursor: pointer;
    color: rgb(0 0 0 / 80%);
  }

  &.react-calendar__month-view__days__day--weekend:not(:disabled) {
    color: #ea4551;
  }
}

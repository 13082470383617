.rangesList {
  position: absolute;
  top: 50px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  width: 140px;
  min-width: 140px;
  align-items: center;
  background-color: var(--new-design--default-dark);
}

.range {
  padding: 16px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.range:hover {
  cursor: pointer;
}

.topBorder {
  border-top: 2px solid #61646680;
}

.rangePickerContainer {
  border-radius: 100px;
  height: 40px;
  width: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  background-color: var(--new-design--default-dark);
  position: relative;
  font-size: 12px;
  font-weight: 700;
  padding: 0 32px;
}

.rangePickerContainer:hover {
  cursor: pointer;
  background-color: var(--btn-hover-color);
}

.rangePickerContainer:active,
.rangePickerContainer:focus {
  background-color: var(--btn-active-color);
}

.preferencesIcon {
  position: relative;
}

.preferencesIcon:hover {
  cursor: pointer;
}

.tooltip {
  position: absolute;
  border-radius: var(--new-design--default-border-radius);
  background: #fff;
  z-index: 1;
  right: 0;
  color: black;
  padding: 16px;
  display: flex;
  flex-direction: column;
}

.preferenceContainer {
  display: flex;
  flex-direction: row;
  font-size: 12px;
  font-weight: 400;
}

.title {
  color: #999;
  margin-right: 5px;
}

.title,
.content {
  white-space: nowrap;
}

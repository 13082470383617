.container {
  display: flex;
  height: 48px;
  align-items: center;
  border-top: 1px solid var(--new-design--default-dark);
  position: relative;
}

.input {
  margin: 0;
  border: 0;
  width: 100%;
  height: 100%;
  padding: 0 16px;
  font-size: 14px;
  background: transparent;
  color: var(--darkTextColor);

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: #616466;
  }
}

.buttons {
  display: flex;
  gap: 12px;
  align-items: center;
  padding-right: 16px;

  button {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.container {
  width: 100%;
}

.containerInner {
  min-height: 100%;
  padding: 17px 0;
}

.photosGrid {
  display: flex;
  flex-wrap: wrap;
}

.photoItem {
  width: 72px;
  height: 72px;
}

.container {
  & + & {
    margin-top: 17px;
  }
}

.title {
  color: #fff;
}

.content {
  color: #808080;
}

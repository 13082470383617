.navButton {
  border-radius: 100px;
  height: 40px;
  width: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  background-color: var(--new-design--default-dark);
  position: relative;
  font-size: 12px;
  font-weight: 700;
}

.navButton:hover {
  background-color: var(--btn-hover-color);
}

.navButton:active,
.navButton:focus {
  background-color: var(--btn-active-color);
}

.activeNavButton {
  background-color: var(--new-design--dark-background);
}

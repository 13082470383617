.iconButton {
  font-size: 0;

  &:hover {
    opacity: 0.7;
  }
  &:active {
    opacity: 0.5;
  }
  &:disabled {
    pointer-events: none;
  }
}

/** Used to define container behavior: width, position: fixed etc... **/
.Toastify__toast-container {
  padding: 0;
  width: auto;
}

/** Used to define the position of the ToastContainer **/
.Toastify__toast-container--top-center {
  top: 24px;
}

/** Classes for the displayed toast **/
.Toastify__toast {
  min-height: auto;
  border-radius: 25px;
  box-shadow: 0 8px 16px 0 rgb(0 0 0 / 30%);
  background-color: #fff;
  padding: 6px 32px 7px;
}

.Toastify__toast-body {
  text-align: center;
  font-family: 'Nunito Sans';
  font-size: 14px;
  color: #000;
}

/** Classes for the progress bar **/
.Toastify__progress-bar {
  height: 0;
}

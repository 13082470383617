.container {
  position: relative;
}

.button {
  width: 40px;
  height: 40px;
  background-color: #2e2e2e;
  border-radius: 50%;
  font-size: 24px;
  text-align: center;
  color: #fff;
  border: 0;
  outline: none;
  cursor: pointer;
  transition: 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    background: none;
    transition: 0.3s ease;
    opacity: 1;
  }

  &:hover {
    background-color: var(--btn-hover-color);
  }

  &:focus,
  &:active {
    background-color: var(--btn-active-color);
  }

  &.disabled,
  &[disabled] {
    cursor: default;

    svg {
      opacity: 0.3;
    }
  }
}

.xs {
  width: 25px;
  height: 25px;
}

.sm {
  width: 32px;
  height: 32px;
}

.md {
  width: 36px;
  height: 36px;
}

.darker {
  background: var(--new-design--default-dark);
}

.white {
  background: #fff;
}

.transparent {
  background: transparent;
}

.container {
  width: 360px;

  input + input {
    margin-top: 16px;
  }
}

.row {
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

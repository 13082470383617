.occupation {
  font-size: 12px;
  font-weight: 400;
  opacity: 0.5;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tooltip {
  position: absolute;
  padding: 16px;
  background: white;
  border-radius: 8px;
  z-index: 1;
  color: black;
  font-size: 12px;
  top: 40px;
}

.tooltipLeft {
  right: calc(100% - 64px);
}

.tooltipRight {
  left: 100%;
}

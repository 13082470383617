.container {
  display: flex;
  flex-direction: column;
}

.right {
  align-items: flex-end;
}

.left {
  align-items: flex-start;
}

.subtext {
  color: #616466;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.right {
  text-align: right;
}

.left {
  text-align: left;
}

.card {
  border: 2px solid black;
  border-radius: 11px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  width: calc(100% - 2 * 2px);
  height: calc(100% - 2 * 2px);
  margin: 2px;

  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  position: relative;
}

.erotic {
  background-color: #f858d4;
}

.general {
  background-color: #c0ea17;
  color: black;
}

.general img {
  filter: brightness(0);
}

.logo {
  margin-top: auto;
  margin-bottom: 10px;
  display: flex;
  position: absolute;
  bottom: 0;
}

.text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;

  margin-top: auto;
  padding: 20px;
}

.outerBorder {
  width: 205px;
  height: 205px;
  display: flex;
  border: 3px solid white;
  border-radius: 11px;
  margin-bottom: 10px;
}

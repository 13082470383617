.container {
  width: 100%;
  height: 42px;
  border-radius: var(--new-design--default-border-radius);
  border: solid 1px #e6e6e6;
  padding-left: 12px;
  padding-right: 12px;
  font-size: 16px;
  transition: 0.3s ease;
  background: none;
  color: #000;

  &:focus {
    outline: none;
  }
}

.containerDark {
  color: #fff;
  border: solid 1px var(--inputBorderColor);

  &:focus {
    border-color: #fff;
  }
}

.required {
  border-color: var(--dangerColor);
}

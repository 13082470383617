.container {
  button {
    min-width: 208px;
    margin-top: 48px;
  }
}

.title {
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 5px;
}

.caption {
  font-size: 18px;
}

.icon {
  width: 180px;
  height: auto;
  margin-bottom: 25px;
}

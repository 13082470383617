.userHeader {
  flex-grow: 1;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  justify-content: space-between;
  width: 20%;
}

.generalInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  max-width: 100%;
  position: relative;
}

.male {
  background: #2c6ff6;
}

.female {
  background: #e94493;
}

.nameAndAge {
  font-size: 14px;
  font-weight: 700;
}

.location {
  font-size: 12px;
  font-weight: 400;
  opacity: 0.5;
}

.textInfo {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  overflow: clip;
}

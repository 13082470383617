.finishedDialogsWrapper {
  width: 100%;
  font-size: 16px;
  align-self: center;
  justify-content: center;
  text-align: center;
  color: var(--whiteBackground);
}

.loaderWrapper {
  align-self: center;
}

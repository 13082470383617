.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 17px;
  font-size: 12px;
}

.editableTitle {
  color: #616466;
  margin-right: 4px;
  cursor: pointer;
  user-select: none;
}

.editableTitle:hover {
  color: var(--btn-hover-color);
}

.editableTitle:active,
.editableTitle:focus {
  color: var(--btn-active-color);
}

.editableContainer {
  position: relative;
  margin-bottom: 4px;
  outline: none;
}

.note {
  border-radius: 4px;
  transition: 0.2s ease-out outline;
  outline: 1px solid transparent;
  margin: 1px -1px;
  padding: 0 1px;

  &:hover {
    outline: 1px solid rgba(255, 255, 255, 0.1);
  }

  &:focus-visible {
    outline: 1px solid rgba(255, 255, 255, 0.2);
  }
}

.noteRow {
  display: flex;
  flex-direction: row;
}

.autoMark {
  display: flex;
  align-items: flex-end;
  margin-right: 3px;
}

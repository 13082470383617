.container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.accessContainer {
  position: relative;
  width: 400px;
  height: 238px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: var(--new-design--default-border-radius);
  background-color: #009f83;

  img {
    margin-bottom: 10px;
  }

  span {
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    color: #fff;
  }

  button {
    position: absolute;
    top: 10px;
    right: 10px;
  }
}

.accessDeniedContainer {
  composes: accessContainer;
  background-color: #ea4551;
}

.accessContainerCloseButton {
  position: absolute;
  top: 0;
  right: 0;

  svg {
    fill: #fff;
  }
}

.closeButton {
  position: absolute;
  top: 20px;
  right: 20px;

  svg {
    fill: #fff;
  }
}

.container {
  width: 400px;
  min-width: 400px;
  padding: 32px;
  border-radius: 16px;
  background-color: #242526;
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
}

.title {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
}

.subtitle {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  color: #616466;
  margin-bottom: 32px;
}

.indicators {
  display: flex;
  flex-direction: row;
  gap: 24px;
}

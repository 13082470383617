.container {
  display: flex;
  text-transform: uppercase;
  height: var(--header-widget-height);
  padding: 0 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 100px;
  background-color: var(--secondaryBackgroundColor);
  font-size: 8px;
  font-weight: 700;
  cursor: pointer;
  position: relative;

  &:hover {
    background-color: var(--inputBorderColor);
  }
}

.onTop {
  background-color: var(--placeholderColor);
}

.onboarding {
  border: 1px solid white;
}

.onboardingHint {
  position: absolute;
  border-radius: 8px;
  background: white;
  color: var(--whiteTextColor);
  top: 100%;
  margin-top: 8px;
  width: 407px;
  font-size: 12px;
  font-weight: 400;
  text-transform: none;
  text-align: center;
  padding: 16px;
  z-index: 1;
  cursor: auto;
}

.closeButton {
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
}

.totalKPI {
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  flex: 1;
  background-color: #242526;
  padding: 20px 32px;
  justify-content: space-between;
  width: 100%;
  min-width: 100%;
}

.total,
.kpiEffect {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
}

.badValue {
  color: #ea4551;
}

.goodValue {
  color: #009f83;
}

.container {
  width: 100%;
}

.containerInner {
  min-height: 100%;
  padding-bottom: 17px;
}

.title {
  font-size: 12px;
  padding: 17px;
}

.photosGrid {
  display: flex;
  flex-wrap: wrap;
}

.photoItem {
  width: 72px;
  height: 72px;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 8px 32px;
}

.button {
  width: 136px;
  font-size: 12px;
}

.label {
  font-size: 8px;
  text-transform: uppercase;
  color: var(--placeholderColor);
  margin-top: 4px;
}

/*
  the style of disabled button required in the feature
  does not align with a common style of disabled buttons
  I hack it with "important" for now and redo it properly if required in future
*/
button.disabledButtonSecondStyle {
  background: var(--new-design--default-dark) !important;
  color: #616466 !important;
}

.disabledButtonSecondStyle:hover {
  cursor: not-allowed;
}

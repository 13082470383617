.row {
  margin-top: 24px;
  text-align: center;

  button + button {
    margin-left: 20px;
  }
}

.inputWrapper {
  text-align: center;
  display: flex;
  align-items: center;

  & + & {
    margin-top: 8px;
  }
}

.inputContainer {
  width: 100%;
  position: relative;
}
.input {
  width: 100%;
}

.input:active,
.input:focus {
  background-color: var(--btn-active-color);
}

.inputLabel {
  flex: 0 0 auto;
  margin-right: 16px;
  color: #999;
}

.error {
  color: var(--dangerColor);
  background-color: #fff;
  font-size: 12px;
  position: absolute;
  border-radius: 4px;
  padding: 0 6px 0;
  left: 20px;
  bottom: -6px;
}

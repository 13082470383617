.container {
  width: var(--chatImageSize);
  height: var(--chatImageSize);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image {
  border-radius: var(--new-design--message-border-radius);
  width: var(--chatImageSize);
  height: var(--chatImageSize);
  object-fit: cover;
}

.overlayContainer {
  width: 100%;
  height: 100%;
  display: flex;
}

.progressContainer {
  z-index: 1;
  position: absolute;
  width: 42px;
  height: 42px;
  border-radius: 21px;
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.5);
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.scale {
  height: 40px;
  border-radius: 100px;
  background-color: #2f3133;
  display: flex;
  align-items: center;
  padding: 0 20px;
  margin-bottom: 8px;
  font-size: 14px;
  white-space: pre;
}

.scale:hover {
  background-color: var(--btn-hover-color);
}

.scale:active,
.scale:focus {
  background-color: var(--btn-active-color);
}

.caption {
  color: #616466;
  font-size: 8px;
  text-transform: uppercase;
}

.tooltip {
  max-width: 320px;
}

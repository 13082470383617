.content {
  font-size: 12px;
  padding: 8px;
}

.tooltip {
  max-width: 300px;
}

.tooltipTitle {
  margin-bottom: 20px;
}

.tooltipContent {
  color: #ea4551;
}

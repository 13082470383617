$size: 160px;
$offset: 16px;
$containerSize: $size * 4 + $offset * 3;

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  position: relative;
  min-width: $containerSize;
  max-width: $containerSize;
}

.text {
  margin-top: $offset;
}

.list {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 40px;
  margin-left: -$offset * 0.5;
  margin-right: -$offset * 0.5;
}

.listItem {
  width: $size;
  height: $size;
  margin: $offset * 0.5;
  border-radius: 20px;
  background-color: #ccc;
  position: relative;
  overflow: hidden;

  & > button {
    position: absolute;
    bottom: $offset;
    right: $offset;
  }
}

.photo {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

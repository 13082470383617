.sidebar {
  max-height: 100%;
  overflow-y: scroll;
  flex-shrink: 0;
  padding-right: 20px;
}

.inner {
  max-width: 140px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  padding-bottom: var(--modal-inner-padding);
}

.item {
  display: flex;
  height: 40px;
  min-height: 40px;
  padding: 0 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background-color: #fafafa;
  color: var(--placeholderColor);
  font-size: 12px;
  font-weight: 700;
}

.selected {
  color: #000;
  background-color: #fff;
  pointer-events: none;
}

.accordionHeader {
  border-radius: 4px;
  background: #2f3133;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  font-weight: 700;
  padding: 0 16px;
  height: 40px;
  cursor: pointer;

  &:hover {
    background: var(--btn-hover-color);
  }

  &:active,
  &:focus {
    background: var(--btn-active-color);
  }
}

.expandedIcon {
  rotate: 180deg;
}

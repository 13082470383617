.chatWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #fff;
  border-radius: var(--new-design--default-border-radius);
}

.chatMiddle {
  flex: 0 1 100%;
  border: 0;
  overflow: hidden;
}

.chatBlock {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  height: 100%;
  overflow: hidden;
  justify-content: space-between;
}

.chatInterlocutor {
  height: 100%;
  display: flex;
  flex-basis: 20%;
  width: 20%;
  flex-grow: 1;

  &.left {
    border-right: 1px solid var(--whiteBorderColor);
  }

  &.right {
    border-left: 1px solid var(--whiteBorderColor);
  }

  :global(.dark) & {
    background-color: var(--secondaryBackgroundColor);
    border-color: var(--new-design--default-dark);
  }
}

.chatLogWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  max-width: 900px;
  height: 100%;
  width: 60%;
  flex-grow: 3;
  background-color: var(--new-design--dark-background);
}

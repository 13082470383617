.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: var(--secondaryBackgroundColor);
  height: 96px;
  min-height: 96px;
  border-bottom: 1px solid var(--new-design--default-dark);
}

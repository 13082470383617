.item {
  max-width: 100%;
  position: relative;
}

.reportBtnWithDeliveryStatus {
  right: 100%;
  transform: translate(21px, -50%);
}

.reportBtnWithoutDeliveryStatus {
  right: 100%;
  transform: translate(45px, -50%);
}

.reportBtnWithDeliveryStatusForOutgoingMessage {
  transform: translate(-1px, -50%);
  right: 0;
}

.reportBtnContainer {
  visibility: hidden;
  position: absolute;
  top: 50%;
  font-size: 9px;
}

.reportBtn {
  border: none;
  background: transparent;
  padding: 5px;
  font-size: 12px;
  line-height: 16px;
  color: var(--dangerColor);
  cursor: pointer;
  user-select: none;
}

.reportWrapperLeft {
  padding-right: 50px;
}

.reportWrapperRight {
  padding-left: 50px;
}

.item:hover .reportBtnContainer {
  visibility: visible;
}

.deliveryStatus {
  position: absolute;
  right: 100%;
  top: 50%;
  transform: translate(calc(42px), -50%);
  font-size: 9px;
}

.deliveryStatusThumbnail {
  width: 16px;
  height: 16px;
  display: block;
  border-radius: 50%;
}

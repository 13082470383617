.menuContainer {
  position: relative;
  font-size: 0;
}

.menu {
  position: absolute;
  border-radius: var(--new-design--default-border-radius);
  background-color: white;
  z-index: 1;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  padding: 4px 0;
}

.bottomLeft {
  right: 0;
  top: calc(100% + 8px);
}

.bottomRight {
  top: calc(100% + 8px);
}

.right {
  top: -50%;
  left: calc(100% + 8px);
}

.topRight {
  bottom: calc(100% + 8px);
}

.trigger {
  cursor: pointer;
}

.textMessageContainer {
  background-color: var(--whiteBackground);
  padding: 8px 16px;
  border-radius: 15px;
}

.titleText {
  color: #999999;
  font-size: 12px;
  margin-bottom: 4px;
}

.textMessage {
  background-color: var(--whiteBackground);
  color: var(--whiteTextColor);
  padding: 0;
  margin-bottom: 4px;
}

.descriptionText {
  color: var(--darkActiveColor);
  font-size: 12px;
}

.container {
  display: inline-flex;
  flex-direction: column;
  padding-top: 8px;

  :global(.chat-image-message) {
    img {
      cursor: pointer;
    }
  }
}

.dayIndicatorWrapper {
  margin: 0 auto 8px;
}

.incomingRow {
  align-items: flex-start;
}

.outgoingRow {
  align-items: flex-end;
}

$audioMessageBottomHeight: 28px;

.audioMessage {
  position: relative;
}

.bottomOffset {
  padding-bottom: $audioMessageBottomHeight;
}

.bottomRow {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  margin-top: 4px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -$audioMessageBottomHeight;
}

.outgoing {
  flex-direction: row-reverse;
}

.messageNote {
  color: var(--placeholderColor);
  font-size: 10px;
  text-wrap: nowrap;
}

.option {
  display: flex;
  flex-direction: row;
  padding: 8px 16px;
  border-radius: var(--new-design--message-border-radius);
  border: 1px solid var(--new-design--default-dark);
  align-items: center;
  position: relative;

  &:hover {
    cursor: pointer;
    background-color: var(--btn-hover-color);
  }

  &:active,
  &:focus {
    background-color: #2f3133;
  }
}

.activeTrap {
  border: 1px solid #ea4551;
}

.optionContainer {
  display: flex;
  flex-direction: column;
  position: relative;
}

.hotkey {
  color: #616466;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 8px;
}

.optionText {
  font-size: 12px;
}

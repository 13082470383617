.radioWrapper {
  position: relative;
}

.radioInput {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
}

.radioLabel {
  user-select: none;
  cursor: pointer;
  display: flex;
  position: relative;
  font-size: 16px;
  font-weight: 400;
  align-items: center;
  justify-content: flex-start;
}

.radioLabel::before {
  display: flex;
  content: ' ';
  height: 16px;
  width: 16px;
  margin-right: 10px;
}

.radioLabelDefault::before {
  border: solid 1px var(--placeholderColor);
}

.radioLabelSelected::before {
  border: solid 1px var(--dangerColor);
}

.radioLabel::after {
  position: absolute;
  top: 6px;
  left: 3px;
  display: flex;
  content: ' ';
  height: 10px;
  width: 10px;
  background: var(--dangerColor);
  transform: scale(0);
  transition: transform 0.3s ease;
}

.radioLabel::before,
.radioLabel::after {
  border-radius: 50%;
  pointer-events: none;
}

.radioWrapper .radioInput:checked + .radioLabel::after {
  transform: scale(1);
}

.container {
  img {
    cursor: pointer;
  }
}

.wrapper {
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 40px;
  justify-items: center;
  align-items: start;
  z-index: 102;
}

.content {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;

  img {
    max-height: 90vh;
  }
}

.closeBtn {
  cursor: pointer;
  position: relative;
  background: #3c515e;
  margin-left: auto;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  align-self: center;

  svg {
    fill: #fff;
    width: 20px;
    height: 20px;
  }
}

.carousel {
  margin: 0 auto;

  img {
    width: auto;
    margin: 0 auto;
    display: block;
  }
}

.hoverOverlayWrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 102;

  img {
    max-height: 90vh;
    max-width: 40vw;
  }
}

.chatTextMessage {
  background-color: var(--darkBorderColor);
  padding: 8px 16px;
  border-radius: var(--new-design--message-border-radius);
  overflow: hidden;
  overflow-wrap: break-word;
  font-size: 14px;
  display: block;
}

.chatTextMessageHighlight {
  background-color: var(--vipBackground);
}

.chatTextMessageActiveHighlight {
  background-color: #ff9632;
}

.outgoing {
  background: var(--whiteActiveColor);
}

.error {
  background: var(--dangerColor);
}

$header-padding: 8px;

.header {
  position: relative;
  z-index: 10;
}

.item {
  position: absolute;
  top: $header-padding;
}

.left {
  left: $header-padding;
}

.center {
  left: 50%;
  transform: translateX(-50%);
}

.right {
  right: $header-padding;
}

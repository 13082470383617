.container {
  margin-top: 64px;
}

.legend {
  margin-bottom: 32px;
}

.table {
  display: flex;
  flex-direction: row;
}

.column {
  flex-grow: 1;
}

.firstRow {
  border-bottom: 1px solid var(--new-design--default-dark);
}

.firstRow,
.secondRow {
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.badValue {
  color: #ea4551;
}

.goodValue {
  color: #009f83;
}

.leftBorder {
  border-left: 1px solid var(--new-design--default-dark);
}

.singleKPI {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 12px;
}

.unactiveKPI,
.goodKPI,
.badKPI,
.mediocreKPI {
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  padding: 8px 12px;
  justify-content: space-between;
  font-size: 12px;
}

.unactiveKPI {
  background-color: var(--new-design--default-dark);
  color: #616466;
}

.goodKPI {
  background-color: #009f83;
}

.mediocreKPI {
  background-color: #ff9632;
}

.badKPI {
  background-color: #ea4551;
}

.chatImageMessage {
  border-radius: var(--new-design--message-border-radius);
  width: var(--chatImageSize);
  height: var(--chatImageSize);
  object-fit: cover;
}

.overlay {
  width: var(--chatImageSize);
  height: var(--chatImageSize);
}

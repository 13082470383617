.container {
  display: flex;
  justify-content: center;
  background-color: var(--new-design--dark-background);
}

.loaderContainer {
  width: 100%;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.closeButton {
  border-radius: 100px;
  background-color: var(--new-design--default-dark);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  justify-self: flex-end;
  position: absolute;
  right: 16px;
}
.label {
  font-weight: bold;
  background: var(--darkBorderColor);
  font-size: 8px;
  border-radius: var(--new-design--default-border-radius);
  padding: 4.5px 10px;
  text-transform: uppercase;
  white-space: nowrap;
  margin-bottom: 5px;
}

.tooltipContainer {
  max-width: 380px;
  text-align: left;
}

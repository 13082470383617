.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 1;
}

.ids {
  font-size: 12px;
  display: flex;
  flex-direction: column;
  margin: auto;
  text-align: center;
  line-height: 2;
}

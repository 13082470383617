.specialOption {
  background-color: var(--new-design--default-dark);
  position: unset;
  z-index: 1;
}

.actionContainer {
  font-size: 0;
  margin-right: 8px;
}

.pickerBtn {
  width: 16px;
  height: 16px;
  pointer-events: none;

  img {
    width: 100%;
    height: 100%;
  }
}

.icebrakerModal {
  transform: unset;
  left: -20px;
  cursor: default;
}

.mediaDropdownMenu {
  bottom: calc(100% + 16px);
  left: -17px;
}

.container {
  display: flex;
  align-items: center;

  input {
    display: none;

    & + span {
      width: 18px;
      height: 18px;
      border: solid 2px #fff;
      background: transparent;
      display: inline-block;
      border-radius: 2px;
      transition: 0.3s ease;
      position: relative;

      svg {
        opacity: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 10px;
        transition: 0.3s ease;
        background-color: transparent;
      }
    }

    &:checked + span {
      background: #00bd90;
      border-color: #00bd90;
      color: #fff;

      svg {
        opacity: 1;
      }
    }
  }
}

.caption {
  margin-left: 9px;
  user-select: none;
}

.picker {
  position: absolute;
  background: #242526;
  bottom: 16px;
  left: 16px;
  right: 16px;
  border-radius: var(--new-design--default-border-radius);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  z-index: 3;
}

.pickerContent {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 22px;
  overflow-y: auto;
  gap: 15px;
}

.pickerItem {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pickerItemButton {
  aspect-ratio: 1 / 1;
  width: 100%;
  max-width: 58px;
  font-size: 0;
  transition: scale 220ms ease-in-out;
  cursor: pointer;

  &:hover {
    scale: 1.2;
  }
}

.pickerItemImage {
  width: 100%;
  height: 100%;
}

.closeButton {
  cursor: pointer;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 8px;
  right: 8px;
}
